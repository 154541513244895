<template>
    <div>
        <loading :active.sync="isLoading" 
            :can-cancel="false" 
            :color="'#aa945b'"
            :is-full-page="fullPage">
        </loading>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader><b>Tag</b></CCardHeader>
                    <CCardBody>
                        <CRow v-if="tag.selectedTag">
                            <CCol sm="6">
                                <CInput label="Name" type="text" v-model="tag.selectedTag.name" disabled></CInput>
                            </CCol>
                            <CCol sm="6">
                                <CInput label="Description" type="text" v-model="tag.selectedTag.description" disabled></CInput>
                            </CCol>
                        </CRow>
                        <CRow class="mb-4">
                            <CCol sm="12">
                                <CButton style="background-color:#998542; color:white" @click="createTagItem">Create Tag Item</CButton>
                            </CCol>
                        </CRow>
                        <CRow v-if="tag.selectedTag">
                            <CCol sm="12">
                            <CDataTable 
                                :items="tag.selectedTag.tag_items"
                                :fields="fields"
                                :noItemsView='{ noResults: "No Tag Items Found", noItems: "No Tags Found" }'
                                hover
                                sorter>
                             
                                <template #editAction="{item, index}">
                                    <td>
                                        <font-awesome-icon icon="edit" style="color:green; cursor: pointer;" class="mr-4;mb-4;" @click="editTagItem(item)"/>
                                    </td>
                                </template>
                                <template #deleteAction="{item, index}">
                                    <td>
                                        <font-awesome-icon icon="trash-alt" style="color:red; cursor: pointer;" class="mt-4;" @click="deleteTagItemConfirmation(item)"/>
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal :title="createTagItemModalTitle" centered :show.sync="createTagItemModal">
            <CRow>
                <CCol sm="12">
                    <CInput label="Name" type="text" v-model="tag_item.name"></CInput>
                </CCol>
                <CCol sm="12">
                    <CInput label="Description" type="text" v-model="tag_item.description"></CInput>
                </CCol>
            </CRow>
            <template slot="footer">
                <CButton color="secondary" @click="cancelTagItemModal">Cancel</CButton>
                <CButton color="success" @click="saveTagItem">Save</CButton>
            </template>
        </CModal>
        <CModal
            title="Confirmation"
            color="success"
            centered
            :show.sync="deleteTagItemModal"
            >
            Are you sure you want to delete a tag?
            <template slot="footer">
                <CButton color="secondary" @click="closeDeleteTagModal">Cancel</CButton>
                <CButton color="success" @click="deleteTagItem">OK</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Vue from 'vue'


export default {
    middleware: "auth",
    name:'Tag',
    data(){
        return{
            createTagItemModal:false,
            createTagItemModalTitle:"",
            deleteTagItemModal:false,
            isLoading: false,
            fullPage: true,
            tag_item:{name:""},
            fields:[
                {key: 'name', label:'Name'},
                {key: 'description', _style:'min-width:300px;', label:'Description'},
                {key: 'editAction', _style:'min-width:20px;', label:''},
                {key: 'deleteAction', _style:'min-width:20px;', label:''},
            ]
        }
    },
    computed: {
        ...mapState(["tag"])
    },
    async mounted(){
        this.isLoading = true;
        await this.$store.dispatch("tag/fetchTagItems", this.$route.params.id);
        this.isLoading = false;
    },
    methods:{
        closeDeleteTagModal(){
            this.deleteTagItemModal = false
        },
        async deleteTagItem(){
            // Call API for delete Tag Item
            if(this.tag_item.id){
                this.isLoading = true;
                let data = {id:this.$route.params.id, item_id:this.tag_item.id}
                await this.$store.dispatch("tag/deleteTagItem", data);
                await this.$store.dispatch("tag/fetchTagItems", this.$route.params.id);
                this.deleteTagItemModal = false
                this.isLoading = false;
            }
        },
        deleteTagItemConfirmation(item){
            this.tag_item = item
            this.deleteTagItemModal = true
        },
        editTagItem(item){
            this.createTagItemModalTitle = "Edit Tag Item (" + item.name +")"
            this.tag_item = item
            this.createTagItemModal = true
        },
        createTagItem(){
            this.createTagItemModalTitle = "Create Tag Item"
            this.tag_item = {}
            this.createTagItemModal = true
        },
        cancelTagItemModal(){
            this.createTagItemModal = false
        },
        async saveTagItem(){
            if(this.tag_item.id){
                // Save Tag Item
                await this.updateTagItem()
            }
            else
            {
                await this.createNewTagItem()
                //alert("Create New Tag Item")
            }

        },
        async updateTagItem(){
            this.isLoading = true;
            await this.$store
                .dispatch("tag/updateTagItem", {
                    item_id: this.tag_item.id,
                    name: this.tag_item.name,
                    description: this.tag_item.description,
                    id:this.$route.params.id
                })
                .then(() => {
                    this.isLoading = false;
                    this.createTagItemModal = false
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                        group: "notify",
                        type: "error",
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
            this.isLoading = false;
            this.editTagModal = false
        },
        async createNewTagItem(){
            this.isLoading = true;
            await this.$store
                .dispatch("tag/createTagItem", {
                    name: this.tag_item.name,
                    description: this.tag_item.description,
                    id: this.$route.params.id,
                })
                .then(() => {
                    this.isLoading = false;
                    //this.$store.dispatch("tag/fetchTagItems", this.$route.params.id);
                    this.createTagItemModal = false
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                        group: "notify",
                        type: "error",
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
            this.isLoading = false;
        },
    }

}
</script>